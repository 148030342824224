import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static debounces = ["updateUrl"];
  static targets = [
    "zipcode",
    "lineThickness",
    "routesSwitch",
    "siteId",
    "siteLoader",
    "statusBarSwitch",
    "lineScale"
  ];

  connect() {
    useDebounce(this, { wait: 1000 });

    this.formState = {};
  }

  updateUrlWithSiteId() {
    const siteId = this.siteLoaderTarget.value;
    const url = new URL(window.location.href);

    console.log("siteId", siteId);
    url.searchParams.set("site_id", siteId);
    window.history.pushState({}, "", url);
    window.location.reload();
  }

  updateUrl() {
    let query = encodeURIComponent(this.zipcodeTarget.value);
    if (query.length > 0) {
      let mapbox_uri = `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&proximity=ip&access_token=pk.eyJ1IjoiY29ycmVjdGVkdGltZSIsImEiOiJZQWpHMy1JIn0.wtBkQp-NkyRbRdcot5o-9g`;
      fetch(new Request(mapbox_uri))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          return response.json();
        })
        .then((json) => {
          if (json != null) {
            let [lon, lat] = json.features[0].geometry.coordinates;
            // console.log(`lon: ${lon}, lat: ${lat}`)

            // This triggers a stimulus action defined on the #map-container
            this.dispatch("setLocation", {
              detail: {
                lat: lat,
                lon: lon,
              },
            });
          }
        });
    }
  }

  updateLayer(target) {
    let tile_params = `?max_age=${
      this.maxAge || 60
    }&cache_bust=${new Date().getTime()}`;

    for (const property in this.formState) {
      tile_params += `&${property}=${this.formState[property]}`;
    }

    if (this.formState["purpose"]) {
      tile_params += "&line_width=10";
    }

    tile_params = tile_params.replace(/&privacy_filter=\d*/, "");

    if (
      this.formState["hideStardEndSwitch"] &&
      this.formState["hideHomeWorkSwitch"]
    ) {
      tile_params += `&privacy_filter=3`;
    } else if (this.formState["hideStardEndSwitch"]) {
      tile_params += `&privacy_filter=1`;
    } else if (this.formState["hideHomeWorkSwitch"]) {
      tile_params += `&privacy_filter=2`;
    } else {
      tile_params += "";
    }

    // This triggers a stimulus action defined on the #map-container
    this.dispatch("setLocation", {
      detail: { tile_params: tile_params }
    });

  }

  updateField(event) {
    const convertedValue = encodeURIComponent(event.target.value);
    if (!convertedValue || convertedValue === "reset") {
      delete this.formState[event.target.dataset.target];
    } else {
      this.formState[event.target.dataset.target] = convertedValue;
    }

    this.updateLayer(event.target.dataset.target);
  }

  updateCheckbox(event) {
    if (event.target.checked) {
      this.formState[event.target.dataset.target] = "true";
    } else {
      delete this.formState[event.target.dataset.target];
    }
    console.log("STATE AFTER", this.formState);

    this.updateLayer(event.target.dataset.target);
  }

  updateLineScale() {
    this.dispatch("setLocation", {
      detail: { line_scale: this.lineScaleTarget.value },
    });
  }

  updateLineThickness() {
    this.dispatch("setLocation", {
      detail: { line_thickness: this.lineThicknessTarget.value },
    });
  }

  updateUnratedRoutes() {
    this.dispatch("setLocation", {
      detail: { hide_unrated: this.routesSwitchTarget.checked },
    });
  }

  updateStatusBar() {
    this.dispatch("setLocation", {
      detail: { hide_status_bar: this.statusBarSwitchTarget.checked },
    });
  }
}
