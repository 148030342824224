import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!window.location.href.includes("/edit")) {
      this.setInitialEndDate();
    }
    this.disablePastDates();

    const yearSelect = document.getElementById("announcement_end_date_1i");
    const monthSelect = document.getElementById("announcement_end_date_2i");

    if (yearSelect && monthSelect) {
      yearSelect.addEventListener("change", this.handleYearChange.bind(this));
      monthSelect.addEventListener("change", this.handleMonthChange.bind(this));
    }
  }

  setInitialEndDate() {
    const currentDate = new Date();
    this.setEndDate({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate(),
    });
  }

  setEndDate(startDate) {
    const endDate = this.addMonthToDate(startDate);

    document.getElementById("announcement_end_date_1i").value =
      endDate.getFullYear();
    document.getElementById("announcement_end_date_2i").value =
      endDate.getMonth() + 1;
    document.getElementById("announcement_end_date_3i").value =
      endDate.getDate();
  }

  addMonthToDate(startDate) {
    const date = new Date(startDate.year, startDate.month - 1, startDate.day); // Adjust month for 0-indexing
    date.setMonth(date.getMonth() + 1);

    if (date.getDate() < startDate.day) {
      date.setDate(0);
    }

    return date;
  }

  disablePastDates() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed
    const currentDay = currentDate.getDate();

    const selectedYear = parseInt(
      document.getElementById("announcement_end_date_1i").value
    );
    const selectedMonth = parseInt(
      document.getElementById("announcement_end_date_2i").value
    );

    // Disable previous years
    Array.from(
      document.getElementById("announcement_end_date_1i").options
    ).forEach((option) => {
      option.disabled = parseInt(option.value) < currentYear;
    });

    // If selected year is the current year
    if (selectedYear === currentYear) {
      Array.from(
        document.getElementById("announcement_end_date_2i").options
      ).forEach((option) => {
        const month = parseInt(option.value);
        option.disabled = month < currentMonth;
      });

      // Disable past days only if the selected month is the current month
      if (selectedMonth === currentMonth) {
        Array.from(
          document.getElementById("announcement_end_date_3i").options
        ).forEach((option) => {
          const day = parseInt(option.value);
          option.disabled = day < currentDay;
        });
      } else {
        // Enable all days for future months in the current year
        Array.from(
          document.getElementById("announcement_end_date_3i").options
        ).forEach((option) => {
          option.disabled = false;
        });
      }
    } else {
      // Enable all months and days for future years
      Array.from(
        document.getElementById("announcement_end_date_2i").options
      ).forEach((option) => {
        option.disabled = false; // Enable all months
      });

      Array.from(
        document.getElementById("announcement_end_date_3i").options
      ).forEach((option) => {
        option.disabled = false; // Enable all days
      });
    }
  }

  handleYearChange() {
    this.disablePastDates();
  }

  handleMonthChange() {
    this.disablePastDates();
  }
}
