import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["studentCurrent", "workerCurrent"];

  connect() {
    this.original = this.data.get("original");
    this.showCurrentStatus();
  }

  showCurrentStatus() {
    if (this.original === "student" && this.hasStudentCurrentTarget) {
      this.studentCurrentTarget.style.display = "block";
    } else if (this.original === "working" && this.hasWorkerCurrentTarget) {
      this.workerCurrentTarget.style.display = "block";
    }
  }

  checkStatus(event) {
    const isStudent = event.target.value === "student";
    const isWorking = event.target.value === "working";

    if (this.hasStudentCurrentTarget) {
      this.studentCurrentTarget.style.display =
        isStudent && this.original === "student" ? "block" : "none";
    }

    if (this.hasWorkerCurrentTarget) {
      this.workerCurrentTarget.style.display =
        isWorking && this.original === "working" ? "block" : "none";
    }
  }
}
