import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "monthsElement",
    "questionsWrapper",
    "roundupEnabled",
    "checkboxContainer",
  ];

  connect() {
    this.hookIntoDateElements();
    this.toggleQuestionsWrapperOnLoad();
    this.checkPreselectedCheckboxes();
  }

  checkPreselectedCheckboxes() {
    const preselectedDates = JSON.parse(
      this.monthsElementTarget.dataset.announcementsAdminRoundupPreselectedValue
    );

    // console.log("Preselected Dates from Data Attribute:", preselectedDates);

    const checkboxes = document.querySelectorAll(".roundup-checkbox");

    checkboxes.forEach((checkbox) => {
      const checkboxDate = checkbox.value;

      preselectedDates.forEach((preselectedDate) => {
        if (preselectedDate.slice(0, 7) === checkboxDate.slice(0, 7)) {
          checkbox.checked = true;
          console.log(`Checkbox with value ${checkboxDate} is checked.`);
        }
      });
    });
  }

  roundupEnabled(event) {
    this.toggleQuestionsWrapper();
  }

  toggleQuestionsWrapperOnLoad() {
    if (this.roundupEnabledTarget.value === "true") {
      this.questionsWrapperTarget.classList.remove("hidden");
    } else {
      this.questionsWrapperTarget.classList.add("hidden");
    }
  }

  toggleQuestionsWrapper() {
    if (this.roundupEnabledTarget.value === "true") {
      this.questionsWrapperTarget.classList.remove("hidden");
    } else {
      this.questionsWrapperTarget.classList.add("hidden");
      this.monthsElementTarget.selectedIndex = 0;
    }
  }

  getDate(name) {
    const dateObject = [
      parseInt(
        document.querySelector(`select[id$=announcement_${name}_date_1i]`).value
      ),
      parseInt(
        document.querySelector(`select[id$=announcement_${name}_date_2i]`).value
      ),
      parseInt(
        document.querySelector(`select[id$=announcement_${name}_date_3i]`).value
      ),
    ];

    return new Date(dateObject);
  }

  hookIntoDateElements() {
    const elements = document
      .querySelectorAll("[data-controller$='announcements-admin-dates']")[0]
      .getElementsByTagName("select");

    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("change", this.handleDateChange.bind(this));
    }
  }

  dateElementChanged(element) {
    document
      .getElementById(element)
      .addEventListener("change", this.handleDateChange.bind(this));
  }

  showCheckboxes() {
    const checkboxes = document.querySelectorAll(".roundup-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.style.display = "block";
    });
  }

  handleDateChange(event) {
    this.checkboxContainerTarget.classList.add(
      "announcements__roundup-checkbox-container__edit"
    );
    this.checkboxContainerTarget.innerHTML = "";
    const items = this.roundupDatesForSelect();

    items.forEach((item) => {
      const monthCheckboxDiv = document.createElement("div");
      monthCheckboxDiv.className = "announcement-month-checkbox";

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.className = "roundup-checkbox";
      checkbox.name = "announcement[roundup_dates][]";
      checkbox.id = `announcement_roundup_dates_${item[1]
        .toISOString()
        .slice(0, 10)}`;
      checkbox.value = item[1].toISOString().slice(0, 10);
      checkbox.setAttribute(
        "data-announcements-admin-roundup-target",
        "monthsElement"
      );

      const label = document.createElement("label");
      label.className = "form-check-label collection_check_boxes";
      label.setAttribute("for", checkbox.id);
      label.textContent = item[0];

      monthCheckboxDiv.appendChild(checkbox);
      monthCheckboxDiv.appendChild(label);

      this.checkboxContainerTarget.appendChild(monthCheckboxDiv);
    });

    // Ensure that checkboxes are properly checked
    this.checkPreselectedCheckboxes();
  }

  roundupDatesForSelect() {
    const startDate = this.getDate("start");
    const endDate = this.getDate("end");

    const firstMonth = startDate > new Date() ? startDate : new Date();

    let dates = [];
    let currentDate = this.advanceDateByOneMonth(firstMonth);

    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = this.advanceDateByOneMonth(currentDate);

      // Stop if we have generated 6 dates
      if (dates.length >= 6) {
        break;
      }
    }

    return dates.map((date) => [
      date.toLocaleString("default", { month: "long" }),
      date,
    ]);
  }

  advanceDateByOneMonth(date) {
    let newDate = new Date(date.getTime());
    newDate.setMonth(date.getMonth() + 1);
    return newDate;
  }

  validMonthSelected() {
    const selected = Array.from(
      this.monthsElementTarget.querySelectorAll(
        'input[type="checkbox"]:checked'
      )
    );
    return selected.length > 0;
  }
}
