import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    attributeName: String,
    locationInputId: String,
    coordsUrlElementId: String,
    updateBtnId: String
  };

  connect() {
    this.site_map = new SiteMap(this.element, document.getElementById(this.coords));
    this.site_map.addDrawingManager();
    this.site_map.addControl(
      new window.ClearControl(this.site_map),
      google.maps.ControlPosition.RIGHT_BOTTOM
    );

    if (this.hasUpdateBtnIdValue) {
      document.getElementById(this.updateBtnIdValue).addEventListener(
        "click",
        (event) => this.onUpdateBtn(event)
      );
    }

    this.setFromCoords()
    if (this.hasLocationInputIdValue) {
      let autocompleteField = document.getElementById(this.locationInputIdValue)
      if (autocompleteField) {
        this.autocomplete = new google.maps.places.Autocomplete(autocompleteField)
        this.autocomplete.bindTo("bounds", this.site_map.getMap())
        google.maps.event.addListener(
          this.autocomplete,
          "place_changed",
          (function (_this) {
            return function () {
              _this.handlePlaceChanged()
            };
          })(this)
        )
      }
    }

  };

  setFromCoords() {
    let url = document.getElementById(this.coordsUrlElementIdValue).dataset.url;
    return $.getJSON(
      url,
      (function (_this) {
        return function (results) {
          return _this.site_map.load_coordinates(results);
        }
      })(this)
    )
  }

  onUpdateBtn(event) {
    event.preventDefault()
    let coords = this.site_map.getCoordinates()
    let location_name = this.getLocationName()
    let location_reference = this.getLocationReference()

    let formData = new FormData()

    formData.append(this.attributeNameValue, coords)
    formData.append("site[location_name]", location_name)
    formData.append("site[location_reference]", location_reference)

    let url = document.getElementById(this.coordsUrlElementIdValue).dataset.url;
    let response = fetch(url, {
      method: "PUT",
      body: formData
    }).then(response => {
      return response
    })
    .then(response => response.text())
      .then(json => {
    })
  }

  getLocationName() {
    return (
      document.getElementById("site_location_name") ||
      document.getElementById("site_region_location_name")
    ).value;
  }

  getLocationReference() {
    return (
      document.getElementById("site_location_reference") ||
      document.getElementById("site_region_location_reference")
    ).value;
  }

  handlePlaceChanged() {
    var place;
    place = this.autocomplete.getPlace();
    this.site_map.set_place(place);

    if (place.reference) {
      document.getElementById("site_location_reference").value = place.reference;
    } else if (place.place_id) {
      document.getElementById("site_location_name").value = place.place_id;
    }


    return $(".disabled-map").removeClass("disabled-map");

  }
}
